import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import { ProtectedRoute } from "./ProtectedRoute";

import Login from "../views/login";
import Logout from "../views/Logout";
import NewsIndex from "views/news";
import CreateNews from "views/news/create";
import EditNews from "views/news/edit";
import NewsItem from "views/news/item";

const Routes = () => {
  const { token } = useAuth();

  // Define public routes accessible to all users
  const routesForPublic = [
    {
      path: "/service",
      element: <div>Service Page</div>,
    },
    {
      path: "/about-us",
      element: <div>About Us</div>,
    },
  ];

  // Define routes accessible only to authenticated users
  const routesForAuthenticatedOnly = [
    {
      path: "/",
      element: <ProtectedRoute />, // Wrap the component in ProtectedRoute
      children: [
        {
          path: "",
          element: <NewsIndex />,
        },
        // {
        //   path: "/profile",
        //   element: <div>User Profile</div>,
        // },
        {
          path: "/news",
          element: <NewsIndex />,
        },
        {
          path: "/news/item/:newsId",
          element: <NewsItem />,
        },
        {
          path: "/news/add",
          element: <CreateNews />,
        },
        {
          path: "/news/edit/:newsId",
          element: <EditNews />,
        },
        {
          path: "/logout",
          element: <Logout />,
        },
      ],
    },
  ];

  // Define routes accessible only to non-authenticated users
  const routesForNotAuthenticatedOnly = [
    {
      path: "/",
      element: <div>Home Page</div>,
    },
    {
      path: "/login",
      element: <Login />,
    },
  ];

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter([
    ...routesForPublic,
    ...(!token ? routesForNotAuthenticatedOnly : []),
    ...routesForAuthenticatedOnly,
  ]);

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />;
};

export default Routes;
